import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import {
  DropzoneInputProps,
  DropzoneOptions,
  DropzoneRootProps,
  useDropzone,
} from "react-dropzone";
import axios, { AxiosResponse } from "axios";
import ErrorModal from "./importCSVErrorModal";

import useNotification from "../notifications/hook";
import { NotificationTypes } from "../notifications/notification-types";
import useError from "../../hooks/useError";
import { useHttpErrorReader } from "../../hooks/useHttpErrorReader";
import DeviceService from "../../services/domain/DeviceService";
import { countFileLines } from "./countFileLines";

type ResponseType = {
  nImportedRows: number;
  nSkippedRows: number;
  nFailedRows: number;
  failedRowDetails: {
    rowNumber: number;
    rowContents: string;
    errorMessages: string[];
  }[];
};

interface ImportCSVProps {
  onSuccess?: () => void;
  dropzoneOptions?: DropzoneOptions;
  service: (formData: FormData) => Promise<AxiosResponse<unknown>>;
  closeAfterSuccess?: boolean;
  refreshData?: () => void;
  importingMessage: string | null;
  timeoutMessage?: (fileLines: number) => string;
}

const Left = styled.div`
  margin-right: auto;
`;

const DropZoneContainer = styled.section`
  display: flex;
  width: 100%;
  height: 140px;
`;

const DropZoneButton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px dashed ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.primary.main}0a;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.palette.primary.main}12;
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.primary.main}1a;
  }
`;

const DropZoneHelpText = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const NoFiles = styled.span`
  opacity: 0.5;
`;

const SelectedFile = styled.div`
  display: flex;
  gap: 0.6rem;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const ImportingMessage = styled.div`
  display: flex;
  gap: 0.6rem;
`;

const SuccessContent = styled.div`
  background-color: ${({ theme }) => theme.palette.brandGray.light};
  padding: 12px;
`;

const SuccessTitle = styled.h4`
  display: flex;
  gap: 0.6rem;
  margin: 0 0 0.75rem;
`;

const RowCount = styled.p<{ nonZero: boolean }>`
  margin: 2px 0;
  color: ${({ nonZero, theme }) =>
    nonZero ? "inherit" : theme.palette.brandGray.dark};

  &:first-child {
    font-weight: 600;
  }

  &:last-child {
    color: ${({ nonZero, theme }) =>
      nonZero ? theme.palette.error.main : theme.palette.brandGray.dark};
  }
`;

const RowDetail = styled.span`
  display: inline-block;
  width: 40px;
  text-align: right;
`;

type StateTransitions = "initial" | "uploading" | "uploaded" | "timeout";

// 50 seconds for the import timeout (for checking csv syntax etc...)
const IMPORT_TIMEOUT = 1000 * 50;
const TIMEOUT_SYMBOL = Symbol("import csv timed out");

const InitialContent = ({
  getRootProps,
  getInputProps,
}: {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
}) => {
  return (
    <DropZoneContainer>
      <DropZoneButton {...getRootProps({ className: "dropzone" })}>
        <input data-testid="fileUploader" {...getInputProps()} />
        <DropZoneHelpText>
          Drop a CSV file here or click to browse
        </DropZoneHelpText>
      </DropZoneButton>
    </DropZoneContainer>
  );
};

const UploadingContent = ({ acceptedFiles }: { acceptedFiles: File[] }) => {
  return (
    <>
      {acceptedFiles.map((acceptedFile) => (
        <ImportingMessage key={acceptedFile.name}>
          <CircularProgress size={18} />
          Importing {acceptedFile.name}, please wait...
        </ImportingMessage>
      ))}
    </>
  );
};

const UploadedContent = ({
  response,
  acceptedFiles,
}: {
  response: ResponseType;
  acceptedFiles: File[];
}) => {
  return (
    <>
      <SuccessTitle>
        <UploadFileIcon />
        {acceptedFiles.map((acceptedFile) => acceptedFile.name).join(", ")}
      </SuccessTitle>
      <SuccessContent>
        <RowCount nonZero={response.nImportedRows > 0}>
          <RowDetail>{response.nImportedRows}</RowDetail>{" "}
          {response.nImportedRows === 1 ? "row" : "rows"} imported successfully
        </RowCount>
        <RowCount nonZero={response.nSkippedRows > 0}>
          <RowDetail>{response.nSkippedRows}</RowDetail>{" "}
          {response.nSkippedRows === 1 ? "row" : "rows"} skipped
        </RowCount>
        <RowCount nonZero={response.nFailedRows > 0}>
          <RowDetail>{response.nFailedRows}</RowDetail>{" "}
          {response.nFailedRows === 1 ? "row" : "rows"} failed with errors
        </RowCount>
      </SuccessContent>
    </>
  );
};

const isFilePlainTextWithCSVExtension = (file: File) =>
  file.type === "text/plain" && file.name.endsWith(".csv");

const isFileHavingCSVMime = (file: File) =>
  file.type === "text/csv" || file.type === "application/vnd.ms-excel";

const ImportCSV: (props: ImportCSVProps) => JSX.Element = ({
  onSuccess,
  dropzoneOptions = {},
  service,
  closeAfterSuccess = false,
  refreshData,
  importingMessage,
  timeoutMessage,
}) => {
  const dropzoneDefaults = {
    multiple: false,
  };

  const [pendingFile, setPendingFile] = useState<File>();

  const acceptedFiles = pendingFile ? [pendingFile] : [];

  const [shouldModalOpen, setShouldModalOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState<StateTransitions>("initial");
  const [response, setResponse] = useState<ResponseType | undefined>();
  const [formattedTimeoutMessage, setTimeoutMessage] = useState<string>("");
  const { handleErrors } = useError();
  const errorReader = useHttpErrorReader();
  const notification = useNotification();

  const checkAndSetPendingFile = (files: File[]) => {
    const firstFile = files[0];
    /**
     * Mime type determination is not reliable across platforms.
     * CSV files, for example, are reported as text/plain under macOS
     * but as application/vnd.ms-excel under Windows.
     * In some cases there might not be a mime type set at all.
     */
    if (firstFile) {
      if (isFilePlainTextWithCSVExtension(firstFile)) {
        setPendingFile(firstFile);
        return;
      }
      if (isFileHavingCSVMime(firstFile)) {
        setPendingFile(firstFile);
        return;
      }
    }
    notification.createNotification({
      type: NotificationTypes.Snackbar,
      message: "Please upload a CSV file",
      severity: "error",
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...dropzoneDefaults,
    ...dropzoneOptions,
    onDrop(files) {
      checkAndSetPendingFile(files);
    },
  });

  useEffect(() => {
    if (!shouldModalOpen) {
      setModalStatus("initial");
      setResponse(undefined);
      setTimeoutMessage("");
    }
  }, [shouldModalOpen]);

  const toggleModal = () => setShouldModalOpen(!shouldModalOpen);
  const onModalClose = () => {
    setPendingFile(undefined);
    setShouldModalOpen(false);
    if (refreshData) {
      refreshData();
    }
  };

  const handleImportClick = async () => {
    // Set file size limit according to the service
    // 1MB for devices, 20MB for library
    const DEVICE_SIZE_LIMIT = 1000000;
    const LIBRARY_SIZE_LIMIT = 20000000;

    const MAX_FILE_SIZE =
      service === DeviceService.importCSVDeviceList
        ? DEVICE_SIZE_LIMIT
        : LIBRARY_SIZE_LIMIT;

    if (pendingFile) {
      // Check file size before uploading
      if (pendingFile.size > MAX_FILE_SIZE) {
        notification.createNotification({
          type: NotificationTypes.Snackbar,
          message: `The file size exceeds the limit of ${
            MAX_FILE_SIZE / 1000000
          }MB.`,

          severity: "error",
        });
        return null;
      }
      setModalStatus("uploading");

      const formData = new FormData();

      formData.append("file", pendingFile as Blob, pendingFile?.name);
      const csvUploadNotificationKey = "csv-import";

      try {
        if (importingMessage) {
          notification.createNotification({
            type: NotificationTypes.Snackbar,
            message: importingMessage,
            severity: "info",
            persistent: true,
            key: csvUploadNotificationKey,
          });
        }
        const res = await Promise.race([
          service(formData),
          new Promise((resolve) => {
            setTimeout(() => resolve(TIMEOUT_SYMBOL), IMPORT_TIMEOUT);
          }),
        ]);

        if (onSuccess) onSuccess();

        if (closeAfterSuccess) {
          setPendingFile(undefined);
          onModalClose();
        } else {
          setModalStatus("uploaded");
        }

        if (res === TIMEOUT_SYMBOL && timeoutMessage) {
          const fileLines = await countFileLines(pendingFile);
          setTimeoutMessage(timeoutMessage(fileLines));
          setModalStatus("timeout");
        }

        if ((res as AxiosResponse)?.data) {
          setResponse((res as AxiosResponse).data);
        }

        if (
          (res as AxiosResponse)?.status === 200 ||
          (res as AxiosResponse)?.status === 204
        ) {
          notification.createNotification({
            type: NotificationTypes.Snackbar,
            message:
              service === DeviceService.importCSVDeviceList
                ? "Your sensor list has been updated"
                : "CSV saved",
            severity: "success",
          });
        }
      } catch (error) {
        // Specific catch block for Axios errors
        if (axios.isAxiosError(error)) {
          const { response: errorResponse } = error;

          if (errorResponse) {
            const { data: error } = errorResponse;
            const errors = Object.entries(
              errorResponse.data.warnings || errorResponse.data.detail
            ).map(([key, value]) => `${key}: ${value}`);

            handleErrors({
              error,
              customHandling: errorReader(errorResponse),
              options: {
                errorMessage: `CSV import failed. Details: ${errors[0]}`,
              },
            });
            const failedRowDetails = errorResponse.data.warnings
              ? Object.entries(errorResponse.data.warnings).map(
                  ([key, value], i) => ({
                    rowNumber: i,
                    rowContents: "",
                    errorMessages: [`${key}: ${value}`],
                  })
                )
              : [];
            setPendingFile(undefined);
            setModalStatus("uploaded");
            return { ...response, failedRowDetails };
          }
          // When error is not a HTTP error (network, CORS,...) especially on Firefox
          // A work around at the moment is that if the error is unknown
          // We are assuming the error is a timeout issue.
          if (timeoutMessage) {
            const fileLines = await countFileLines(pendingFile);
            setTimeoutMessage(timeoutMessage(fileLines));
            setModalStatus("timeout");
          } else {
            setPendingFile(undefined);
            onModalClose();

            // General catch block for other possible errors
            notification.createNotification({
              type: NotificationTypes.Snackbar,
              message: `CSV import failed. Details: ${error.message}`,
              severity: "error",
            });
          }
        }
      } finally {
        notification.dismissPersistentNotification(csvUploadNotificationKey);
      }

      return null;
    }
    return null;
  };

  // Handle modal content depending on state

  const getContent = () => {
    switch (modalStatus) {
      case "uploading":
        return <UploadingContent acceptedFiles={acceptedFiles} />;

      case "uploaded":
        return (
          response && (
            <UploadedContent
              acceptedFiles={acceptedFiles}
              response={response}
            />
          )
        );
      case "timeout":
        return <span>{formattedTimeoutMessage}</span>;
      case "initial":
      default:
        return (
          <InitialContent
            getInputProps={getInputProps}
            getRootProps={getRootProps}
          />
        );
    }
  };

  const getLeftControl = () => {
    switch (modalStatus) {
      case "timeout":
      case "uploading":
        return null;
      case "uploaded":
        return (
          response && (
            <ErrorModal acceptedFiles={acceptedFiles} response={response} />
          )
        );

      default:
      case "initial":
        return pendingFile?.name ? (
          <SelectedFile>
            <UploadFileIcon />
            {pendingFile?.name}
          </SelectedFile>
        ) : (
          <NoFiles>No file selected</NoFiles>
        );
    }
  };

  const getRightControl = () => {
    switch (modalStatus) {
      case "uploading":
        return null;
      case "uploaded":
      case "timeout":
        return <Button onClick={onModalClose}>Close</Button>;
      case "initial":
      default:
        return (
          <>
            <Button
              data-testid="cancel-import-button"
              onClick={onModalClose}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              data-testid="start-import-button"
              onClick={handleImportClick}
              disabled={!pendingFile}
            >
              Import
            </Button>
          </>
        );
    }
  };

  return (
    <>
      <Button data-testid="import-csv-button" onClick={toggleModal}>
        Import CSV
      </Button>
      <Dialog
        data-testid="import-modal"
        onClose={onModalClose}
        open={shouldModalOpen}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Import CSV</DialogTitle>
        <DialogContent>{getContent()}</DialogContent>
        <DialogActions>
          <Left>{getLeftControl()}</Left>
          {getRightControl()}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportCSV;
